<template>
    <el-tabs v-model="outerActiveName" type="border-card" class="service_tabs" @tab-click="handleOuterTabClick">
        <el-tab-pane name="first" class="tab-pane-content">
            <template #label>
                <span class="custom-tabs-label">
                <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/life.svg"/>
                <span>生活服务项目</span>
                </span>
            </template>
            <div class="life">
                <div class="life_title">服务单位：上海赛蒂国际货运运输代理有限公司</div>
                <el-tabs v-model="innerActiveName" class="life_tabs" @tab-click="handleInnerClick">
                    <el-tab-pane label="咖啡场地及咖啡优惠券包" name="coffee">
                        <div class="life_content">
                            <div class="l_c_adr">
                                <div class="map-container">
                                    <mapPage 
                                        ref="coffeeMap"
                                        v-if="showMaps.coffee"
                                        :longitude="121.500017" 
                                        :dimension="31.29199" 
                                        :zoom="15"
                                        key="coffee-map">
                                    </mapPage>
                                </div>
                            </div>
                            <div class="l_c_info">
                                <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/coffee.png"/>
                                <div>服务地址：<span>虹口区伊敏河路30号i56coffee</span></div>
                                <div>服务时间：<span>周一至周五，每天8:30～17:30；周六至周日，9:30-18:30</span></div>
                                <div><span class="l_c_i_r">咖啡券包名称：</span><span>【上海市国际货运代理行业协会会员单位专属优惠券包】</span></div>
                                <div><span class="l_c_i_r">咖啡券包内容(有效期30天，可重复领取)：</span><span>20-15优惠券*3张/20-10优惠券*3张/40-20优惠券*3张/60-30优惠券*3张/</span></div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="羽毛球活动-“赛蒂羽你同行”" name="badminton">
                        <div class="life_content">
                            <div class="l_c_adr">
                                <div class="map-container">
                                    <mapPage 
                                        ref="badmintonMap"
                                        v-if="showMaps.badminton"
                                        :longitude="121.508748" 
                                        :dimension="31.294753" 
                                        :zoom="15"
                                        key="badminton-map">
                                    </mapPage>
                                </div>
                            </div>
                            <div class="l_c_info">
                                <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/badminton.png"/>
                                <div>活动地址：<span>复旦科技园体育中心 杨浦区密云路1018号（院内可停车）</span></div>
                                <div>活动时间：<span>每2周一次，周三18:00-20:00</span></div>
                                <div>参与人数：<span>20人/场次</span></div>
                                <div>报名方式：<span>链接扫码报名，活动开始前3天截止报名（方便预订场地），羽毛球拍自带</span></div>
                                <div>联系人：<span>李俐薇 19821856106</span></div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-tab-pane>
        <el-tab-pane name="second" class="tab-pane-content">
            <template #label>
                <span class="custom-tabs-label">
                <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/finance.svg"/>
                <span>金融服务项目</span>
                </span>
            </template>
            <div class="finance">
                <div class="finance_title">服务单位：宁波银行上海普陀支行</div>
                <div class="finance_content">
                    <div class="l_c_adr">
                        <div class="map-container">
                            <mapPage 
                                ref="financeMap"
                                v-if="showMaps.finance"
                                :longitude="121.414989" 
                                :dimension="31.233158" 
                                :zoom="15"
                                key="finance-map">
                            </mapPage>
                        </div>
                    </div>
                    <div class="l_c_info">
                        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/ningbo_bank.png"/>
                        <div>服务单位：<span>宁波银行上海普陀支行</span></div>
                        <div><span class="l_c_i_r">服务内容：</span><span>金融业务咨询及办理</span></div>
                        <div>服务地点：<span>普陀区中山北路3553号（线下）</span></div>
                        <div>服务时间：<span>周一至周五9：00-16：30，周六9：30-16：30</span></div>
                        <div>联系人：<span>王子一，江洁</span></div>
                        <div>联系电话：<span>17866538979，18817501889，15000856106</span></div>
                    </div>
                </div>
            </div>
        </el-tab-pane>
        <el-tab-pane name="third" class="tab-pane-content">
          <template #label>
                <span class="custom-tabs-label">
                <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/warehouse.svg"/>
                <span>仓储服务项目</span>
                </span>
            </template>
            <div class="empty-tab-content">
                <div class="empty-placeholder">
                    <i class="el-icon-box"></i>
                    <p>暂无仓储服务项目</p>
                </div>
            </div>
        </el-tab-pane>
    </el-tabs>
</template>
<script>
import mapPage from "~/baseComponents/map";

export default {
  components: { mapPage },
  data() {
    return {
      outerActiveName: "first",
      innerActiveName: "coffee",
      showMaps: {
        coffee: false,
        badminton: false,
        finance: false
      }
    };
  },
  methods: {
    handleInnerClick(tab) {
      console.log('Inner tab clicked:', tab.name);
      this.innerActiveName = tab.name;
      
      // 重置所有内部地图显示状态
      this.showMaps.coffee = false;
      this.showMaps.badminton = false;
      
      // 延迟显示当前选中的地图
      this.$nextTick(() => {
        if (tab.name === 'coffee') {
          this.showMaps.coffee = true;
        } else if (tab.name === 'badminton') {
          this.showMaps.badminton = true;
        }
        console.log('DOM updated after inner tab change, maps state:', this.showMaps);
      });
    },
    handleOuterTabClick(tab) {
      console.log('Outer tab clicked:', tab.name);
      
      // 重置所有地图显示状态
      this.showMaps.coffee = false;
      this.showMaps.badminton = false;
      this.showMaps.finance = false;
      
      // 延迟显示当前选中的地图
      this.$nextTick(() => {
        if (tab.name === 'first') {
          // 生活服务项目
          if (this.innerActiveName === 'coffee') {
            this.showMaps.coffee = true;
          } else if (this.innerActiveName === 'badminton') {
            this.showMaps.badminton = true;
          }
        } else if (tab.name === 'second') {
          // 金融服务项目
          this.showMaps.finance = true;
        }
        
        console.log('DOM updated after outer tab change, maps state:', this.showMaps);
        this.adjustTabPaneHeights();
      });
    },
    // 手动初始化地图
    initMaps() {
      if (this.outerActiveName === 'first') {
        if (this.innerActiveName === 'coffee') {
          this.showMaps.coffee = true;
        } else if (this.innerActiveName === 'badminton') {
          this.showMaps.badminton = true;
        }
      } else if (this.outerActiveName === 'second') {
        this.showMaps.finance = true;
      }
    },
    adjustTabPaneHeights() {
      setTimeout(() => {
        const tabPanes = document.querySelectorAll('.tab-pane-content');
        
        // 首先重置所有面板高度，以便正确计算内容高度
        tabPanes.forEach(pane => {
          pane.style.minHeight = 'auto';
        });
        
        // 找出最高的面板
        let maxHeight = 0;
        tabPanes.forEach(pane => {
          const height = pane.scrollHeight;
          if (height > maxHeight) {
            maxHeight = height;
          }
        });
        
        // 设置所有面板为同一高度
        tabPanes.forEach(pane => {
          pane.style.minHeight = `${maxHeight}px`;
        });
        
        console.log('Tab panes adjusted to height:', maxHeight);
      }, 200);
    }
  },
  mounted() {
    console.log('Component mounted');
    // 初始化地图显示
    this.$nextTick(() => {
      this.initMaps();
      this.adjustTabPaneHeights();
    });
    
    // 监听窗口大小变化，重新调整高度
    window.addEventListener('resize', this.adjustTabPaneHeights);
  },
  beforeDestroy() {
    // 移除事件监听，避免内存泄漏
    window.removeEventListener('resize', this.adjustTabPaneHeights);
  },
  watch: {
    outerActiveName(newVal) {
      console.log('outerActiveName changed to:', newVal);
    },
    innerActiveName(newVal) {
      console.log('innerActiveName changed to:', newVal);
    }
  }
};
</script>
<style lang="less" scoped>
  .service_tabs .custom-tabs-label .img {
    vertical-align: middle;
  }
  .service_tabs .custom-tabs-label span {
    vertical-align: middle;
    margin-left: 4px;
  }

  .finance,
  .life {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 15px;
    
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
    
    .finance_title,
    .life_title {
      font-size: 20px;
      font-weight: 500;
      padding-bottom: 10px;
      width: 100%;
      text-align: center;
    }

    // 其他样式保持不变
    .life_tabs {
      width: 100%;
      margin-top: 20px;
    }

    .finance_content {
        margin-top: 20px;
    }



    .finance_content,
    .life_content {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: calc(100% - 20px); // 减去左右间距
      margin: 10px; // 前后左右间距均为10px
      padding: 16px;
      background-color: white;
      border: 0px solid #e0e0e0;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 0 0 4px 4px;
      
      .s_c_adr,
      .l_c_adr {
        width: 55%;
        height: 400px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        overflow: hidden;

        .map-container {
          width: 100%;
          height: 100%;
        }
      }
      
      .l_c_info {
        width: 45%;
        height: 400px;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        
        & > * {
          margin-bottom: 15px;
          width: 100%;
          text-align: left;
        }
        
        .l_c_i_r {
          color: #ff3333; // 使用鲜红色
        }
        
        span:not(.l_c_i_r) {
          font-weight: 500;
        }
      }
    }

    .adress {
      width: 100%;
      height: 400px;
      // border: 1px solid #ff5155;
    }
  }

  // 标签样式
  .service_tabs {
    :deep(.el-tabs__content) {
      overflow: visible; // 确保内容不被裁剪
    }
    
    .custom-tabs-label {
      display: flex;
      align-items: center;
      
      img {
        width: 18px;
        height: 18px;
      }
      
      span {
        margin-left: 5px;
      }
    }
    
    .tab-pane-content {
      transition: min-height 0.3s; // 平滑过渡效果
      min-height: 600px; // 初始最小高度
    }
  }
  
  // 空内容占位样式
  .empty-tab-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    padding: 20px;
    
    .empty-placeholder {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      i {
        font-size: 48px;
        color: #DCDFE6;
        margin-bottom: 20px;
      }
      
      p {
        font-size: 16px;
        color: #909399;
      }
    }
  }
  </style>
