<template>
  <div class="contactUs">
    <div class="content_area">
      <div class="title"><img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/party.svg">  党建服务电子地图</div>
      <div class="main">
        <template v-if="LOCALE == 'en'">
          <div>
            To better serve the member units of the association, achieve resource sharing, mutual benefit, and jointly promote the high-quality development of the Shanghai Freight Forwarding Industry Association, the Party Branch of the Shanghai International Freight Forwarders Association plans to create a Shanghai International Freight Forwarders Association Party Building Service Electronic Map.
          </div>
          <div>
            Service Items: Living Services, Warehousing Services, Tax Services, Financial Services, Human Resources Services, etc. For each service item, 2-3 member units will be selected from those that apply to provide services and will be displayed on the electronic map.
          </div>
          
        </template>
        <template v-else>
          <div>
            为更好地为协会会员单位提供服务，实现会员单位资源共享、互利互惠，携手推进上海货代行业协会高质量发展，上海市国际货代协会党支部拟创建上海市国际货代协会党建服务电子地图。
          </div>
          <div>
            服务项目：生活服务、仓储服务、税务服务、金融服务、人力服务等，每个服务项目从申报提供服务的会员单位中筛选2-3家在电子地图中展示。
          </div>
        </template>
      </div>
      <cabin></cabin>
    </div>
  </div>
</template>
<script>
import mapPage from "~/baseComponents/map";
import cabin from '~sif/components/party/index';

export default {
  components: { mapPage,
    cabin
   },
 metaInfo() {
    return {
      title: "党建服务",
    };
  },
  data() {
    return {
    };
  },
  methods: {

  },
  
  mounted() {},
};
</script>
<style lang="less" scoped>
.contactUs {
  min-height: 800px;
  background: #ffffff;
  .content_area {
    padding: 30px 0 50px;
    .title {
      font-size: 22px;
      img {
        margin-bottom: 5px;
      }
    }
    .main {
      margin: 24px 0;
      div {
        font-size: 16px;
        text-indent: 2em;
        line-height: 25px;
      }
    }
    .service_tabs {
      margin: 20px 0;
      
      /deep/ .el-tabs__header {
        display: flex;
        justify-content: center;
      }
      
      /deep/ .el-tabs__item {
        font-size: 16px;
      }
      
      /deep/ .el-tabs__content {
        margin-top: 10px;
        text-align: left;
        width: 100%;
        
        & > * {
          text-align: left;
        }
      }
    }
    .adress {
      height: 210px;
      // border: 1px solid #ff5155;
    }
  }

}
</style>